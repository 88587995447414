import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as variable from "../../CSS Variable/Variable";
import Logo from "../../asset/Images/Logo.png"
import { Link } from 'react-router-dom';

const FooterBox = styled(Box)({
  background: `${variable.Primary}`,
  color: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  padding: '2rem',
  marginTop: "5vh",
  height: "40%",
  '@media(max-width: 768px)': {
    height: 'auto',
    padding: '2rem 1rem'
  }
});

const ColumnBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem'
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10%',
  width: "70%",
  '@media(max-width: 768px)': {
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    alignItems: "flex-start"
  }
});

const LogoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
  textAlign: 'center',
  width: "30%",
  '@media(max-width: 600px)': {
    height: '50%',
    width: '100%'
  }
});

const FlexBox = styled(Box)({
  display: "flex",
  gap: "20%",
  alignItems: "center",
  '@media(max-width: 768px)': {
    flexDirection: "column",
    width: '100%',
    gap: "5vh"
  }
})

const LogoImg = styled("img")`
height : 9vh;
 width : 6vw;
 @media (max-width: 600px) 
 {
  height : 7vh;
  width : 7vw;
}

`

const Footer = () => {
  return (
    <FooterBox>
      <FlexBox>
        <LogoBox>
          <Link to="/">
            <LogoImg src={Logo} />
          </Link>
          <Typography variant="body2">
            We are one of the best tour and travel company which gives you the best tour and takes you to your 
            destination with full of comfort and satisfaction.Customer satisfaction is our uppermost priority.
          </Typography>
        </LogoBox>
        <RowBox>
          <ColumnBox sx={{width : "30%"}}>
            <Typography variant="h6" fontWeight="bold">
              QUICK LINKS
            </Typography>
            <Typography variant="body2">Home</Typography>
            <Typography variant="body2">About Us</Typography>
            <Typography variant="body2">Services</Typography>
            <Typography variant="body2">Gallery</Typography>
            <Typography variant="body2">Contact Us</Typography>
            <Link to="/TermsAndConditions" style={{textDecoration : "none" , color : "white"}}><Typography variant="body2">Terms And Conditions</Typography></Link>
          </ColumnBox>
          <ColumnBox>
            <Typography variant="h6" fontWeight="bold">
              SERVICES
            </Typography>
            <Typography variant="body2">Airport Transfer</Typography>
            <Typography variant="body2">Premium Taxi Transfer</Typography>
            <Typography variant="body2">Corporate Services</Typography>
          </ColumnBox>
          <ColumnBox>
            <Typography variant="h6" fontWeight="bold">
              CONTACT US
            </Typography>
            <Typography variant="body2">
              Address: 1st Floor , MI ARCADE
              SHARANPUR ROAD ,SUBHASH NAGAR
              DEHRADUN, Uttarakhand
              <Typography variant="body2"> PIN - 248001 </Typography>

            </Typography>
            <Typography variant="body2">Mobile: +91 8171999201 , +91 8171999203</Typography>
            <Typography variant="body2">Email: aayantaxiservice@gmail.com</Typography>
            <Typography variant="body2" >Managed By : <Link style={{textDecoration : "none" , color : "white"}} to="https://aayantourandtravel.com/" >Aayan Tour And Travel</Link></Typography>
          </ColumnBox>
        </RowBox>
      </FlexBox>
    </FooterBox>
  );
};

export default Footer;
