import React, { useState } from 'react'
import { Box, Typography, styled, Tabs, Tab, Button } from '@mui/material'
import * as variable from "../../CSS Variable/Variable"
import OneWay from './OneWay'
import RoundTrip from './RoundTrip'
import Local from './Local'
import Airport from './Airport'


const TabPanel = ({ children, value, index, width }) => {
  return (
    <div role="tabpanel" hidden={value !== index} style={{ width: `${width}` }}>
      {value === index && <div>{children}</div>}
    </div>
  );
};



function Search() {

  const FlexColBox = styled(Box)`
display : flex; 
flex-direction : column;
align-content : center;
justify-content : center;
align-items : center;
gap : 5vh;
margin : 3%
width : 100%;
`
  const SearchContainer = styled(Box)`
  width : 90%;
  margin : auto;
  height : 40vh;
  border-radius : 10px;
  background : rgb(255,250,250 , 0.8);
  position: relative;
  top: 8vh;
  @media (max-width: 600px) {
    height : max-content;
  }
`

  const SearchHeader = styled(Typography)`
    background : ${variable.Primary};
    color : white;
    border-radius : 10px;
    height : 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align : center;
    font-weight : 600;
    @media (max-width: 600px)
    {
       
   }`
 

  const TabsBox = styled(Tabs)`
 margin-top : 1%;
 font-weight : 700;
 @media (max-width: 600px)
 {
    
}`
 

  const StyledTab = styled(Tab)`
 border: solid 1px;
 color: black;
 border-color : blue;
 background : white;
 
 
 &.Mui-selected  {
  background-color : blue;
  color : white;
  border-color : blue;
 }

 &.css-1aquho2-MuiTabs-indicator {
  height : 0px;
 }

 @media (max-width: 600px)
 {
  width: 0%;
  padding: 0px;
  min-height: 32px; 
}

 `

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <SearchContainer>

        <SearchHeader variant='h5'>WHERE ARE YOU GOING ? </SearchHeader>
        <FlexColBox>
          <TabsBox value={value} onChange={handleChange}>
            <StyledTab label="One Way" />
            <StyledTab label="Round Trip" />
            <StyledTab label="Rental" />
            {/* <StyledTab label="Airport" /> */}
          </TabsBox>
          <TabPanel value={value} index={0} width="80%">
            <OneWay />
          </TabPanel>
          <TabPanel value={value} index={1} width="90%" >
            <RoundTrip />
          </TabPanel>
          <TabPanel value={value} index={2} width="60%" >
            <Local />
          </TabPanel>
          <TabPanel value={value} index={3} width="80%" >
            <Airport />
          </TabPanel>
        </FlexColBox>
      </SearchContainer >

    </>
  )
}


export default Search