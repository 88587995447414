import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Checkbox, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as variable from "../../CSS Variable/Variable"
import styled from 'styled-components';
import { Link, redirect, useNavigate } from 'react-router-dom';
import CabContext from "../../CabContext"

function BookNow({ Price, BtnName, formDetails }) {
    const navigate = useNavigate()
    const [payNow, setPayNow] = useState(false);
    const [payLater, setPayLater] = useState(false);
    const context = useContext(CabContext)
    const { setPaymentType, paymentType } = context;
    let transactionID = "";
    const hostName = process.env.REACT_APP_SERVER


    const SquareBox = styled(FormControlLabel)`
`;

    const Label = styled('span')`
        font-size: 0.8rem;
        font-weight : 600;
        
    `;

    const BookNowButton = styled(Button)`
        margin-top : 10vh;
    `;

    const onChangePayLater = () => {
        setPayLater(!payLater)
        setPaymentType("Pay Later")

    }
    const onChangePayNow = () => {
        setPayNow(!payNow)
        setPaymentType("Pay Now")

    }

    const submitForm = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDetails),
            redirect: 'follow'
        };

        try {

            await fetch(`${hostName}/trip/post`, requestOptions);

        } catch (error) {
            console.log('error', error);
        }

    }

    const genTransactionID = () => {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        transactionID = `${year}${month}${day}${hours}${minutes}${seconds}` ;
    }

    const Pay = async () => {
        try {
            genTransactionID()
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "amount": Price * 100,
                "userID" : "user1562",
                "transactionID": transactionID,
                "redirectUrl": "https:taxiapp.leetwolf.in/booking/checkPaymentStatus"
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(`${hostName}/payment/paymentInitiate`, requestOptions)
            const result = await response.json();
            window.location.href = result.data.instrumentResponse.redirectInfo.url

        } catch (error) {
            console.log(error.message)
        }
    }

    const handleBook = async () => {

        if (BtnName === "Book Now") {
            if (payLater === true && payNow === true || payLater === false && payNow === false) {
                alert("Please Select One Payment Option")
            }
            else {
                navigate("/Booking")
            }
        }

        else if (BtnName === "Confirm Booking") {
            if ('travellerName' in formDetails && 'emailId' in formDetails && 'contactNumber' in formDetails) {
                if (paymentType === "Pay Later") {
                    submitForm();
                    navigate("/BookingSuccess")
                }
                else if (paymentType === "Pay Now") {
                    Pay();
                }
            }
            else {
                alert("All Fields are compulsory to filled.")
            }
        }

    }


    return (
        <Box sx={{ padding: '1rem' }}>
            <Typography variant="h5" sx={{}}>
                Total Amount
            </Typography>
            <Typography variant="h3" sx={{}}>
                Rs. {Price}
            </Typography>
            <Typography variant="body2" color="darkblue" sx={{ marginBottom: '1rem', fontSize: "0.7rem" }}>
                (Incl. of Tolls and Taxes)
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '5rem',
                }}
            >
                {
                    BtnName === "Book Now" ?
                        <FormGroup>
                            <SquareBox control={<Checkbox checked={payNow} />} onChange={onChangePayNow} label={<Label>Pay Now</Label>} />
                            <SquareBox control={<Checkbox checked={payLater} />} onChange={onChangePayLater} label={<Label>Pay Later</Label>} />
                        </FormGroup> : <Typography>By clicking on CONFIRM BOOKING , you agree to our <Link to="/TermsAndConditions">Terms And Conditions</Link></Typography>}

            </Box>

            <BookNowButton variant="contained" size="large" onClick={handleBook} sx={{ width: '100%', background: `${variable.Primary};` }}>{BtnName}</BookNowButton>
        </Box>
    );
};
export default BookNow