import React from "react";
import styled from "@mui/material/styles/styled";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import * as variable from "../../CSS Variable/Variable";
import { useNavigate } from "react-router-dom";
import CabContext from "../../CabContext";
import { useContext } from "react";

const CardContainer = styled(Card)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  border: `1px solid ${(theme) => theme.palette.grey[300]}`,
  background: "#F7F7F7",
  marginBottom: "15px",
  "@media (max-width: 600px)": {
    width: "95%",
    marginBottom: "2px",
  },
});

const Media = styled(CardMedia)({
  width: "200px",
  height: "200px",
  borderRadius: (theme) => theme.shape.borderRadius,
  "@media (max-width: 600px)": {
    width: "70px",
    height: "70px",
  },
});

const Content = styled(CardContent)({
  flex: 1,
  padding: "0 16px",
});

const Title = styled(Typography)({
  marginBottom: "0.5rem",
});

const Bullets = styled(List)({
  margin: 0,
  padding: 0,
});

const BulletItem = styled(ListItem)({
  padding: "0",
  color: "grey",
  margin: "0",
  "@media (max-width: 600px)": {
    fontSize: "10px",
  },
});

const Price = styled(Typography)({
  marginBottom: "0.5rem",
});

const BookNowContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const BookNowButton = styled(Button)({
  background: `${variable.Primary}`,
  color: "white",
  borderRadius: "15px",
  border: `solid 1px blue`,
  "&:hover": {
    background: "transparent",
    color: "blue",
  },
});

const TCell = styled(TableCell)({
  padding: "0.25rem",
  border: "none",
});

const ResultsCard = ({
  btnShow,
  CabType,
  cabPrice,
  img,
  carName,
  seats,
  extraFare,
  fuelType,
  cabDistance,
  tripType,
  fareForRental,
  typeForRental,
}) => {
  const navigate = useNavigate();
  const context = useContext(CabContext);
  const { setBookingAmount, setCabType, setImg, setCarName, setRentalType } = context;

  if (tripType == "round" || cabDistance <= 100) {
    var finalPrice = Math.round(2 * cabDistance * cabPrice);
    // console.log(finalPrice);
  } else if (tripType == "local") {
    var finalPrice = fareForRental;
  } else {
    var finalPrice = Math.round(cabDistance * cabPrice);
  }

  const handleBook = (Price, cabType, img, carName) => {
    setBookingAmount(Price);
    setCabType(cabType);
    setImg(img);
    setCarName(carName);
    setRentalType(typeForRental)
    navigate("/CabDescription");
  };

  return (
    <CardContainer>
      <Media image={img} title={carName} />
      <Content>
        <Title variant="h5" sx={{ fontWeight: 700 }}>
          {carName}{" "}
        </Title>
        <Bullets>
          <BulletItem>
            <ListItemText primary="AC" />
          </BulletItem>
          <BulletItem>
            <ListItemText primary={seats} />
          </BulletItem>
        </Bullets>
        {CabType === "hatchback" ? (
          <Typography variant="h6"> Compact Car </Typography>
        ) : (
          <Typography variant="h6"> Spaced Car </Typography>
        )}
        <Table>
          <TableBody>
            <TableRow>
              <TCell>Extra km fare</TCell>
              <TCell>
                <TCell>
                  {fareForRental && typeForRental === "8 hrs 80km"
                    ? `Rs.${Math.round(fareForRental / 8 - 50)}/km after 80 km`
                    : fareForRental && typeForRental === "12 hrs 120km"
                    ? `Rs.${Math.round(
                        fareForRental / 12 - 50
                      )}/km after 120 km`
                    : `Rs.${extraFare} after 250 km`}
                </TCell>
              </TCell>
            </TableRow>
            <TableRow>
              <TCell>Fuel Type</TCell>
              <TCell>{fuelType}</TCell>
            </TableRow>
            <TableRow>
              <TCell>Cancellation</TCell>
              <TCell>Free cancellation up to 24 hrs before pickup .</TCell>
            </TableRow>
          </TableBody>
        </Table>
      </Content>
      {btnShow ? (
        <BookNowContainer>
          <Price variant="h4" sx={{ fontWeight: 700 }}>
            {" "}
            Rs.{finalPrice}
          </Price>
          <BookNowButton
            variant="contained"
            onClick={() => handleBook(finalPrice, CabType, img, carName)}
          >
            {" "}
            Book Now{" "}
          </BookNowButton>
        </BookNowContainer>
      ) : null}
    </CardContainer>
  );
};

export default ResultsCard;
