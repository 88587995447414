import React, { useState } from 'react'
import TransactionSuccess from './TransactionSuccess'
import TransactionFailed from './TransactionFailed'
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';

function Transaction() {
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState("");

    const paymentCheck = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "payload": {
                    "transactionId": "159873665",
                    "userID": ""
                }
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            const response = await fetch("https://backend.savaaritaxi.com/payment/paymentVerification", requestOptions)
            // const response = await fetch("http://localhost:2000/payment/paymentVerification", requestOptions)
            const result = await response.json();
            setPaymentStatus(result.paymentStatus)
        }
        catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        paymentCheck();
        setLoading(false)
      
    }, []);
    

    return (
        <>
            {loading && <div style={{ textAlign: "center", height: "100vh", display: "flex", alignItems: 'center', justifyContent : "center" }}>
                <div>
                    <h1>Checking Payment Status ...</h1>
                    <CircularProgress size={60} />
                    <p>Please do not close the window. </p>
                </div>
            </div>}
            {!loading && paymentStatus=="SUCCESS"?<TransactionSuccess /> : !loading && paymentStatus=="FAILED"? <TransactionSuccess/> : null }
        </>
    )
}

export default Transaction