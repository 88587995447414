import React, { useState, useEffect, forwardRef } from 'react';
import { TextField, Box, styled, Autocomplete, IconButton, Button } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCabSearchDetails } from '../../action/CabAction'
import * as variable from "../../CSS Variable/Variable"
import { useImperativeHandle } from 'react';

const FormInput = styled(TextField)`
  border-radius: none;
  margin: 1%;
  background: #ffffff;
  border-color: blue;
  color : blue;
  @media (max-width: 600px) {
    width : 100%;
    margin-bottom : 10px;
    border: none;
    height : 5%;
  }
`;

const HorizontalBox = styled(Box)({
  display: 'inline-block',
  verticalAlign: 'top',
});

const SwapIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
  left: "12px",
  fontSize: '2rem',
  backgroundColor: 'blue',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  "@media (max-width: 600px)": {
    display: "none"
  }
}));

const SearchButton = styled(Button)`
background : ${variable.Primary};
color : white;
border-radius : 10px;
height : 18%;
min-width : 10%;
font-size: 1.5rem;
font-weight : 600;
padding : 0 4% ;
position: absolute;
left: 43%;
top: 90%;
@media (max-width: 600px)
 {
    height: 10%;
    padding: 5% 21%;
    font-weight: 400;
    font-size: 1.5rem;
    top: 94%;
    position: relative;
    left: 0;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}`

const FieldBox = styled(Box)`
display : flex ; 
gap : 3%;
@media (max-width: 600px) {
flex-direction: column;
}
`

const RoundTrip = forwardRef(({ width, BtnHide }, ref) => {

  const [searchDetails, setSearchDetails] = useState({});
  const [options, setOptions] = useState([]);
  const [fromValue, setFromValue] = useState('Dehradun');
  const [toValue, setToValue] = useState('Haridwar');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10);
  const todayTime = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  const hostName = process.env.REACT_APP_SERVER

  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchDetails({ ...searchDetails, [name]: value })
  };

  const handleSearch = () => {
    dispatch(getCabSearchDetails(searchDetails))
    console.log(searchDetails);
    navigate("/SearchedResult")
  }

  useEffect(() => {
    setSearchDetails({
      from: fromValue,
      to: toValue,
      pickupDate: todayDate,
      returnDate: todayDate,
      pickupTime: todayTime,
      tripType: "round"
    });
  }, []);


  const handleInputChange = async (event, value) => {
    const response = await fetch(
      `${hostName}/autocomplete?input=${value}`
    );
    const data = await response.json();
    if (Array.isArray(data)) {
      setOptions(data);

    }
    else {
      setOptions([])
    }
  };

  const isOptionEqualToValue = (option, value) =>
    option.description === value.description;

  useImperativeHandle(ref, () => ({
    handleSearch
  }));


  return (
    <>
      <form ref={ref} onSubmit={handleSearch}>
        <FieldBox sx={{ width: `${width}`, "@media (max-width: 600px)": { width: "68vw" } }}>
          <HorizontalBox sx={{ width: "25%" }}>
            <Autocomplete
              options={options}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.description}
              sx={{ "@media(max-width: 600px)": { width: "68vw" } }}
              isOptionEqualToValue={isOptionEqualToValue}
              name="from"
              onChange={(e, v) => {
                setFromValue(v.terms[0].value);
                setSearchDetails({ ...searchDetails, "from": v.terms[0].value })
              }}
              renderInput={(params) => (
                <FormInput
                  {...params}
                  label="From"
                  name="from"
                  variant="outlined"
                  // onChange={onChange}
                  value={fromValue}
                />
              )}
            />
          </HorizontalBox>

          {!BtnHide && <Box sx={{ position: 'absolute', width: '5%', left: "359px", top: "178px", zIndex: 5 }}>
            <SwapIconButton
              onClick={() => {
                setSearchDetails({ ...searchDetails, from: toValue, to: fromValue });
                setFromValue(toValue);
                setToValue(fromValue);
              }} >
              <SwapHorizIcon sx={{ color: "white" }} />
            </SwapIconButton>
          </Box>}

          <HorizontalBox sx={{ width: "25%" }}>
            <Autocomplete
              options={options}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.description}
              sx={{ "@media(max-width: 600px)": { width: "68vw" } }}
              isOptionEqualToValue={isOptionEqualToValue}
              name="to"
              onChange={(e, v) => {
                setToValue(v.terms[0].value);
                setSearchDetails({ ...searchDetails, "to": v.terms[0].value })
              }}
              // defaultValue={{"terms" : [{value : {toValue}}]}}
              // defaultValue={toValue}
              renderInput={(params) => (
                <FormInput
                  {...params}
                  label="To"
                  variant="outlined"
                // onChange={onChange}
                // value={toValue}
                />
              )}
            />
          </HorizontalBox>

          <HorizontalBox>
            <FormInput
              label="Departure"
              defaultValue={todayDate}
              type='date'
              name="pickupDate"
              variant="outlined"
              onChange={onChange}
            />
          </HorizontalBox>
          <HorizontalBox>
            <FormInput name="returnDate"
              label="Return"
              defaultValue={todayDate}
              type="date"
              variant="outlined"
              onChange={onChange} />
          </HorizontalBox>

          <HorizontalBox>
            <FormInput
              label="Pickup Time"
              defaultValue={todayTime}
              type="time"
              name="pickupTime"
              variant="outlined"
              onChange={onChange}
            />
          </HorizontalBox>
        </FieldBox>
        {!BtnHide && <SearchButton variant='contained' type='submit' >Search</SearchButton>}
      </form>
    </>
  );
})

export default RoundTrip;
