import React, { useState } from "react";
import {
  AppBar,
  Button,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../asset/Images/Logo.png";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { SocialIcon } from "react-social-icons";

const StyledHeader = styled(AppBar)`
  background: white;
  height: 13vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: black;
  @media (max-width: 600px) {
    height: 8vh;
  }
`;

const NavListContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const LogoBox = styled(Box)`
  height: 8.5vh;
  width: 5.2vw;
  @media (max-width: 600px) {
    height: 5vh;
    width: 20vw;
  }
`;

const NavListItem = styled(Typography)`
  margin-left: 2%;
  width: 122px;
  text-align: center;
  padding: 2% 0%;
  text-align: center;
  cursor: pointer;

  &hover: {
    color: red;
  }
`;

const LogoImg = styled("img")`
padding : 5px;
  height: inherit;
  width: inherit;
  @media (max-width: 600px) {
    height: 40px;
    width: 50px;
  }
`;

const Icon = styled(SocialIcon)`
  margin: 0 5px;
`;
const MobileIcon = styled(SocialIcon)`
  margin: 0 5px;
`;

function Header() {
  const navigate = useNavigate();

  const handleNav = (takeTo) => {
    if (takeTo === "aboutUs") {
      // const section = document.getElementById('aboutUs');
      // section.scrollIntoView({ behavior: 'smooth' });

      const section = document.getElementById("aboutUs");
      const sectionPos = section.offsetTop;
      window.scrollTo({ top: sectionPos, behavior: "smooth" });
      navigate("/");
    } else if (takeTo === "Services") {
      const section = document.getElementById("services");
      const sectionPos = section.offsetTop;
      window.scrollTo({ top: sectionPos, behavior: "smooth" });
      navigate("/");
    } else if (takeTo === "Gallery") {
      const section = document.getElementById("gallery");
      const sectionPos = section.offsetTop;
      window.scrollTo({ top: sectionPos, behavior: "smooth" });
      navigate("/");
    } else if (takeTo === "Contact") {
      navigate("/");
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    } else if (takeTo === "Home") {
      navigate("/");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const DrawerContent = (
    <List>
      <ListItem button onClick={toggleDrawer}>
        <ListItemText primary="Home" onClick={() => handleNav("Home")} />
      </ListItem>
      <ListItem button onClick={toggleDrawer}>
        <>
          <ListItemText
            primary="About Us"
            onClick={() => handleNav("aboutUS")}
          />
        </>
      </ListItem>
      <ListItem button onClick={toggleDrawer}>
        <ListItemText
          primary="Services"
          onClick={() => handleNav("Services")}
        />
      </ListItem>
      <ListItem button onClick={toggleDrawer}>
        <ListItemText primary="Gallery" onClick={() => handleNav("Gallery")} />
      </ListItem>
      <ListItem button onClick={toggleDrawer}>
        <ListItemText primary="Contact" onClick={() => handleNav("Contact")} />
      </ListItem>
    </List>
  );

  return (
    <>
      <StyledHeader position="fixed">
        <Toolbar
          style={{
            width: "90vw",
            justifyContent: "space-between",
            height: "inherit",
          }}
        >
          <Link to="/">
            {" "}
            <LogoBox>
              <LogoImg src={Logo} />
            </LogoBox>
          </Link>

          {isMobile ? (
            <>
              <Box>
                <MobileIcon
                  url="https://www.facebook.com"
                  label="Facebook"
                  style={{ height: 25, width: 25 }}
                />
                <MobileIcon
                  url="https://www.instagram.com"
                  label="Instagram"
                  style={{ height: 25, width: 25 }}
                />
                <MobileIcon
                  url="https://jsdl.in/DT-99WKIKYXSI4"
                  label="Just-Dial"
                  style={{ height: 25, width: 25 }}
                />
              </Box>
              <Button color="inherit" onClick={toggleDrawer}>
                <DehazeIcon sx={{ fontSize: "22px" }} />
              </Button>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                {DrawerContent}
              </Drawer>
            </>
          ) : (
            <>
              <Box>
                <Icon url="https://www.facebook.com" label="Facebook" />
                <Icon url="https://www.instagram.com" label="Instagram" />
                <Icon url="https://jsdl.in/DT-99WKIKYXSI4" label="Just-Dial" />
              </Box>
              <NavListContainer>
                <NavListItem onClick={() => handleNav("Home")}>
                  Home
                </NavListItem>
                <NavListItem onClick={() => handleNav("aboutUs")}>
                  About Us
                </NavListItem>
                <NavListItem onClick={() => handleNav("Services")}>
                  Services
                </NavListItem>
                <NavListItem onClick={() => handleNav("Gallery")}>
                  Gallery
                </NavListItem>
                <NavListItem onClick={() => handleNav("Contact")}>
                  Contact
                </NavListItem>
              </NavListContainer>
            </>
          )}
        </Toolbar>
      </StyledHeader>
      <Offset />
    </>
  );
}

export default Header;
