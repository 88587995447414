import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Img1 from "../../asset/Images/Taxi2.jpeg"
import Img2 from "../../asset/Images/Taxi.png"
import Img3 from "../../asset/Images/Taxi3.jpeg"
import Img4 from "../../asset/Images/Taxi4.jpeg"
import Img5 from "../../asset/Images/Taxi5.jpeg"
import Img6 from "../../asset/Images/Taxi6.jpeg"
import Img7 from "../../asset/Images/Taxi7.jpeg"
import Img8 from "../../asset/Images/Taxi8.jpeg"
import Img9 from "../../asset/Images/Taxi9.jpeg"

const GalleryBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    '@media(max-width: 600px)':
    {
       width : "90vw"   
    }
});

const GalleryRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '20px',
    '@media(max-width: 600px)':
    {
        display: 'flex',
        flexDirection: 'column'
    }
});

const GalleryImage = styled.img({
    width: '400px',
    height: '400px',
    margin: '10px',
    objectFit: "contain",
    border : "solid 1px blue",
    '@media(max-width: 600px)':
    {
        width: '100%',
        height: 'auto',
        margin: "0"
    }
});



function Gallery() {
    return (
        <GalleryBox>
            <Typography variant="h4" sx={{ '@media(min-width: 600px)': { textTransform: "uppercase", fontWeight: "600" } }}>Gallery</Typography>
            <GalleryRow>
                <GalleryImage src={Img7} alt="image7" />
                <GalleryImage src={Img8} alt="image8" />
                <GalleryImage src={Img9} alt="image9" />
            </GalleryRow>
            <GalleryRow>
                <GalleryImage src={Img1} alt="image1" />
                <GalleryImage src={Img2} alt="image2" />
                <GalleryImage src={Img3} alt="image3" />
            </GalleryRow>
            <GalleryRow>
                <GalleryImage src={Img4} alt="image4" />
                <GalleryImage src={Img5} alt="image5" />
                <GalleryImage src={Img6} alt="image6" />
            </GalleryRow>
        </GalleryBox>
    );
}

export default Gallery;
