import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, styled } from "@mui/material";
import { SocialIcon } from 'react-social-icons';
import { Link } from 'react-router-dom';

const IconBox = styled(Box)`
display : none;
@media (max-width: 480px)
{
 position : fixed;
top : 78vh;
left : 80vw;
z-index : 15000;
display : flex;
flex-direction : column;
gap : 5px;
}
`

const CallIcon = styled(PhoneIcon)`
color : Blue;
font-size : 2.5rem;
background : white;
border-radius : 50%;
padding : 10px;
`
const Icon = styled(SocialIcon)`
background : white;
border-radius : 50%;
`

function FloatingIcons() {
  return (
    <IconBox>
        <Icon url="https://api.whatsapp.com/send?phone=918171999201" label="whatsapp" />
       <Link to= {`tel:${8171999201}`}> <CallIcon/> </Link>
    </IconBox>
  )
}

export default FloatingIcons