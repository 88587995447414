import {
  SEARCH_FOR_CAB_REQUEST,
  SEARCH_FOR_CAB_SUCCESS,
  SEARCH_FOR_CAB_FAIL,
  GET_CABDETAILS_REQUEST,
  GET_CABDETAILS_SUCCESS,
  GET_CABDETAILS_FAIL,
} from "../Constants/CabConstants";
const hostName = process.env.REACT_APP_SERVER;

export const getCabDetails = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CABDETAILS_REQUEST });
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const response = await fetch(`${hostName}/cars/get`, requestOptions);
    const cabDetails = await response.json();
    const result = cabDetails;

    dispatch({
      type: GET_CABDETAILS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: GET_CABDETAILS_FAIL,
      payload: error.message,
    });
  }
};

export const getCabSearchDetails = (query) => async (dispatch) => {
  let result = {};
  try {
    dispatch({ type: SEARCH_FOR_CAB_REQUEST });
    if (query.tripType !== "local") {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const response = await fetch(
        `${hostName}/destination/search?from=${query.from}&to=${query.to}&tripType=${query.tripType}&pickupDate=${query.pickupDate}&returnDate=${query.returnDate}`,
        requestOptions
      );
      const cabSearchResults = await response.json();
      result = { userQuery: query, searchedResult: cabSearchResults };
    } else {
      result = {
        userQuery: query,
        searchedResult: {
          success: true,
          data: { Distance: 0, Distance_Unit: "km" },
        },
      };
    }

    dispatch({
      type: SEARCH_FOR_CAB_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_FOR_CAB_FAIL,
      payload: error.response.data.message,
    });
  }
};
