import React, { useContext } from 'react'
import FiltersSidebar from "./FiltersSidebar"
import Header from '../Header/Header'
import { Box, Button, Toolbar, styled } from '@mui/material'
import RoundTrip from '../Search/RoundTrip'
import * as variable from "../../CSS Variable/Variable"
import ResultsCard from './ResultsCard'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Footer from "../Footer/Footer"
import NoCabsAvailable from './NoCabAvialable'
import SearchHeader from '../SerachHeader/SearchHeader'
import Loader from '../Loader/Loader'

const FilterBox = styled(Box)`
width : 20%;
position : sticky;
@media (max-width: 600px) {
    display : none;
    }
`
const PageContainer = styled(Box)`
margin: auto;
margin-top : 5vh;
width: 84vw;
max-width: 1500px;
min-width: 1150px;
@media (max-width: 480px) {
    max-width: 480px;
    min-width: 100vw;
    margin-top : 2vh;
    }
`

const SearchContainer = styled(Box)`
display : flex;
align-items : center;
width: 100%;
margin: auto;
background : #F7F7F7;
margin-bottom : 10px;
position: sticky;
top: 13vh;
z-index: 1000000;
@media (max-width: 600px) {
    display : none;
    }
`
const ResultsContainer = styled(Box)`
display : flex;
gap : 1%;
`

function SearchResult() {
    const {loading, searchDetails } = useSelector((state) => state.searchCab)
    const { cabDetails } = useSelector((state) => state.cabDetails)

   
    return (
        <>
            <Header />
            {loading && <PageContainer><Loader /></PageContainer>}

            {!loading && searchDetails.searchedResult.success === true ? <PageContainer>
                <SearchContainer>
                    <SearchHeader />
                </SearchContainer>

                <ResultsContainer>
                    {/* <FilterBox>
                        <FiltersSidebar />
                        <Toolbar />
                    </FilterBox> */}

                    <Box sx={{ width: "100%" }}>
                        {cabDetails.map((e)=>{
                           return <ResultsCard btnShow={true} carName={e.carName}  img={e.image} CabType={e.carType} cabPrice={e.carPrice}
                            cabDistance={searchDetails.searchedResult.data.Distance} seats={e.seats} extraFare = {e.extraFare} fuelType = {e.fuelType}
                           tripType = {searchDetails.userQuery.tripType} />
                        })}
                    </Box>

                </ResultsContainer>
            </PageContainer> :
                <PageContainer>
                    <SearchContainer>
                        <SearchHeader />
                    </SearchContainer>
                    <NoCabsAvailable />
                </PageContainer>
            }
            <Footer />
        </>
    )
}

export default SearchResult