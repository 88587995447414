import React, { useEffect } from 'react'
import Header from '../Header/Header'
import Search from '../Search/Search'
import { Box, styled, Typography } from '@mui/material'
import Image from "../../asset/Images/IMAGE.jpg"
import AboutUs from './AboutUs'
import Gallery from './Gallery'
import Features from './Features'
import TestimonialSlider from './TestimonialSlider'
import Footer from '../Footer/Footer'
import { Link } from 'react-scroll';
import Services from './Services'
import { useDispatch, useSelector } from 'react-redux'
import { getCabDetails } from '../../action/CabAction'

const ImageBox = styled(Box)`
display : block;
height : 100vh;
object-fit : cover;
background-image : url(${Image});
background-size : cover;
background-position : center;
background-repeat : no-repeat;
`

  const AboutUsBox = styled(Box)`
width : 90%;
margin : auto;
margin-top : 10vh;
height : 30%
`

  const GalleryBox = styled(Box)`
width : 93%;
margin : auto;
margin-top : 10vh;
`

  const FeaturesBox = styled(Box)`
width : 90%;
margin : auto;
margin-top : 10vh;
`
  const ServicesBox = styled(Box)`
width : 90%;
margin : auto;
margin-top : 10vh;
`

  const TestimonialBox = styled(Box)`
width : 70%;
margin : auto;
margin-top : 10vh;
 @media (max-width: 600px)
{
  display : none;
}
`

function Homepage() {
  
  const dispatch = useDispatch()
  const cabsDetails = useSelector((state)=>state.cabDetails)
  // console.log(cabsDetails)

  const testimonials = [
    {
      image: "https://via.placeholder.com/150",
      name: "Jai Badri Vishal",
      review:
        "Great experience with at an tour n travel thanks for giving very genuine and good service.",
    },
    {
      image: "https://via.placeholder.com/150",
      name: "Khushi Patel",
      review:
        "Good service and easy booking time par available hai ☺️ I really liked the service",
    }
  ]

  const cabDetails=()=>{
    dispatch(getCabDetails())
  }

  useEffect(() => {
    cabDetails()
  }, [])
  

  return (
    <>
      <Header />
      <ImageBox>
        <Search />
      </ImageBox>
      <Link to="aboutUs" smooth={true}>
        <AboutUsBox id='aboutUs'>
          <AboutUs />
        </AboutUsBox>
      </Link>
      <ServicesBox  id='services'>
      <Services/>
      </ServicesBox>

      <Link to="gallery" smooth={true}>
        <GalleryBox id='gallery'>
          <Gallery />
        </GalleryBox>
      </Link>

      <FeaturesBox>
        <Features />
      </FeaturesBox>
      <TestimonialBox>
        <Typography variant="h4" sx={{ marginBottom: "2rem", textTransform: "uppercase", textAlign: 'center', fontWeight: '600' }}>
          Testimonials
        </Typography>
        <TestimonialSlider testimonials={testimonials} />
      </TestimonialBox>
      <Footer />

    </>
  )
}

export default Homepage