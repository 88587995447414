import React from 'react'
import Header from '../Header/Header'
import { Box, styled } from '@mui/material'
import ResultsCard from '../SearchResult/ResultsCard'
import Description from './Description'
import BookNow from './BookNow'
import { useContext } from 'react'
import CabContext from "../../CabContext"
import Footer from "../Footer/Footer"
import SearchHeader from '../SerachHeader/SearchHeader'


const PageContainer = styled(Box)`
  margin: auto;
  margin-top : 6vh;
  width: 84vw;
  max-width: 1500px;
  min-width: 1150px;
  @media (max-width: 600px) {
    width: 100vw;
    max-width : 600px;
    min-width : 100vw;
    margin-top : 3vh;
    }
  `

const FlexContainer = styled(Box)`
  margin : auto;
    width: 84vw;
    display : flex;
    gap : 15px;
    @media (max-width: 600px) {
      width: inherit;
     flex-direction : column
      }
    `
const LeftContainer = styled(Box)`
       width : 70%;
       @media (max-width: 600px) {
        width : 100%;
         }
    `
const RightContainer = styled(Box)`
       width : 30%;
       text-align : center;
       background : #f5f5f5;
       height : fit-content;
       @media (max-width: 600px) {
        width : 100%;
         }
    `
const SearchContainer = styled(Box)`
    display : flex;
    align-items : center;
    width: 100%;
    margin: auto;
    background : #F7F7F7;
    margin-bottom : 10px;
    `

function BookingPage() {
  const context = useContext(CabContext)
  const { bookingAmount, cabType , img , carName , rentalType} = context;

  return (
    <>
      <Header />

      <PageContainer>
        <SearchContainer>
          <SearchHeader />
        </SearchContainer>
        <FlexContainer>
          <LeftContainer>
            <ResultsCard btnShow={false} CabType={cabType} img = {img} carName = {carName} fareForRental = {bookingAmount} typeForRental={rentalType}/>
            <Description />
          </LeftContainer>
          <RightContainer>
            <BookNow Price={bookingAmount} BtnName="Book Now" />
          </RightContainer>
        </FlexContainer>
      </PageContainer>
      <Footer />
    </>
  )
}

export default BookingPage