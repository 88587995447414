import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// import Checkbox from '@mui/material/Checkbox';
import Header from '../Header/Header';
import BookNow from '../BookingPage/BookNow';
import { useContext } from 'react';
import CabContext from "../../CabContext"
import Footer from "../Footer/Footer"
import { useSelector } from 'react-redux';


const PageContainer = styled(Box)`
    margin: auto;
    margin-top : 6vh;
    width: 84vw;
    max-width: 1500px;
    min-width: 1150px;
    @media (max-width: 600px) {
        width: 100vw;
        max-width : 600px;
        min-width : 100vw;
        margin-top : 3vh;
        }
    `
const FlexContainer = styled(Box)`
    margin : auto;
      width: 84vw;
      display : flex;
      gap : 15px;
      @media (max-width: 600px) {
        width: inherit;
       flex-direction : column
        }
      `
const LeftContainer = styled(Box)`
      width : 70%;
      background : #f5f5f5;
      @media (max-width: 600px) {
        width : 100%
         }
   `
const RightContainer = styled(Box)`
      width : 30%;
      text-align : center;
      background : #f5f5f5;
      height : max-content;
      @media (max-width: 600px) {
        width : 100%
         }
     `
const Input = styled(TextField)`
     background : white;
     display : block;
     width : 100%;
     border : none ;
     `
const Label = styled(Typography)`
     margin-bottom  : 0;
     display : inline;
     `

function BookingForm() {
    const [details, setDetails] = useState({}) 
    const context = useContext(CabContext)
    const { bookingAmount , carName } = context;
    const tripDetails = useSelector((state)=>state.searchCab.searchDetails.userQuery)
     
    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails({ ...details, [name]: value });
    };

    useEffect(() => {
        setDetails({ ...details,pickupAddress : tripDetails.from , dropoffAddress : tripDetails.to , gender : "NA"  , "amount": bookingAmount });
    }, []);

    return (
        <>
            <Header />
            <PageContainer>
                <FlexContainer>
                    <LeftContainer>
                        <Box sx={{ padding: '1rem', "@media(max-width: 600px)":{paddingLeft : "3px"} }}>
                            <Typography variant="h5" sx={{ marginBottom: '1rem' , fontWeight : "600", color : "#0500FF" }}>  Trip Details</Typography>



                            {/* <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '1rem' , "@media(max-width: 600px)":{flexDirection : "column"} }}>
                                <Box sx={{ flexGrow: 1 }}>

                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>Pick-up Address</Label>
                                    <Label variant="caption" sx={{ color: 'text.secondary', fontSize: '0.8rem' }}>This will help our driver to reach you on time </Label>

                                    <Input
                                        placeholder="Enter your pick-up address"
                                        variant="outlined"
                                        name="pickupAddress"
                                        fullWidth
                                        onChange={handleChange} />
                                </Box>

                                <Box sx={{ flexGrow: 1 }}>

                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>Drop-Off Address</Label>
                                    <Label variant="caption" sx={{ color: 'text.secondary', fontSize: '0.8rem' }}> (Optional - This will help you avoid any extra charges after the trip)</Label>

                                    <Input
                                        placeholder="Enter your drop-off address (optional)"
                                        variant="outlined"
                                        name="dropoffAddress"
                                        fullWidth
                                        onChange={handleChange}
                                        required
                                    />

                                </Box>
                            </Box> */}
                            <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '1rem' , "@media(max-width: 600px)":{flexDirection : "column"} }}>
                                <Box sx={{ flexGrow: 1 }} >

                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem', fontWeight : "600"}}>From : </Label>
                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>{tripDetails.from}</Label>
                                </Box>
                                <Box sx={{ flexGrow: 1 }} >

                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem', fontWeight : "600"}}>To : </Label>
                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>{tripDetails.to}</Label>
                                </Box>
                               
                                <Box sx={{ flexGrow: 1 }} >

                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem', fontWeight : "600"}}>Vehicle Type : </Label>
                                    <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>{carName}</Label>
                                </Box>


            
                            </Box>

                            <Label variant="h5" sx={{ marginBottom: '1rem',  fontWeight : "600" , color : "#0500FF"}}>Confirm Traveller Information</Label>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
                                <Box sx={{ display: 'flex', gap: '1rem', "@media(max-width: 600px)":{flexDirection : "column"} }}>
                                    <Box sx={{ width: "100%" }}>

                                        <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' , fontWeight : "600"  }}>Name</Label>
                                        <Input
                                            placeholder="Name"
                                            variant="outlined"
                                            name="travellerName"
                                            fullWidth
                                            onChange={handleChange}
                                            required
                                        />
                                    </Box>

                                </Box>

                                <Box sx={{ display: 'flex', gap: '1rem' , "@media(max-width: 600px)":{flexDirection : "column"} }}>
                                    <Box sx={{ width: "100%" }}>

                                        <Label variant="subtitle1" sx={{ marginBottom: '0.5rem'  , fontWeight : "600" }}>Email ID</Label>
                                        <Input
                                            placeholder="Email ID"
                                            variant="outlined"
                                            fullWidth
                                            name="emailId"
                                            type="email"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Label variant="subtitle1" sx={{ marginBottom: '0.5rem' , fontWeight : "600" }}>Contact</Label>
                                        <Input
                                            placeholder="Contact Number"
                                            name="contactNumber"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            onChange={handleChange} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Checkbox
                            checked={agreeToTerms}
                            onChange={(e) => setAgreeToTerms(e.target.checked)}
                            color="primary"
                            sx={{display : "inline"}}
                        /> */}

                    </LeftContainer>
                    <RightContainer>
                        <BookNow Price={bookingAmount} BtnName="Confirm Booking" formDetails={details} />
                    </RightContainer>
                </FlexContainer>
            </PageContainer>
            <Footer />
        </>
    )
}

export default BookingForm


