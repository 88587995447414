import React from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';


const TransactionMessageWrapper = styled(Paper)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 300px;
    margin: 0 auto;
    margin-top : 20vh;
  }

  &.failure {
    background-color: #dc3545; 
  }

  .icon {
    font-size: 60px;
    margin-bottom: 10px;
  }
`;

const TransactionFailed = () => {
  return (
    <TransactionMessageWrapper className="failure">
      <div className="icon">&#10007;</div>
      <h2>Transaction Failed</h2>
    </TransactionMessageWrapper>
  );
};

export default TransactionFailed;
