import React from 'react'
import styled from 'styled-components';
import { Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const TextContainer = styled.div`
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
`;

const BackButton = styled(Button)`
  margin-top: 20px !important;
`;


function TermsAndCondition() {
const navigate=useNavigate()
  
const goBack=()=>{
navigate(-1)
}

  return (
    <>
      <Container>
        <TextContainer>
          <Typography variant="h5"sx={{textAlign : "center"}} gutterBottom>
            Terms and Conditions
          </Typography>
      
          <Typography variant="body1" gutterBottom>
            - Users must be at least 18 years old to use our services. By using our website, you confirm that you meet this requirement.
          </Typography>
          <Typography variant="body1" gutterBottom>
          - Savaari Taxi provides an online platform that connects users with taxi services. We do not own or operate any taxi services but act as an intermediary.
          </Typography>
          <Typography variant="body1" gutterBottom>
            - Users can book taxi services through our website. Payment details, including fares and additional charges, will be clearly communicated during the booking process.
          </Typography>
          
          <Typography variant="body1" gutterBottom>
            - Users agree to use our services responsibly and comply with all applicable laws and regulations
          </Typography>
          <Typography variant="body1" gutterBottom>
            - Any misuse of the platform, including fraudulent activities, will result in immediate termination of services.
          </Typography>
          <Typography variant="body1" gutterBottom>
            - All content and materials on the Savaari Taxi website are the property of Savaari Taxi and are protected by intellectual property laws.
          </Typography>
          <Typography variant="body1" gutterBottom>
            - Savaari Taxi is not liable for any damages, losses, or injuries arising from the use of our services, including but not limited to accidents, injuries, or delays.
          </Typography>
          <Typography variant="body1" gutterBottom>
            - For any questions or concerns regarding these terms, users can contact us at savaaritaxicar@gmail.com </Typography>

          <Typography variant="body1" gutterBottom>
            - Savaari Taxi reserves the right to modify these terms and conditions at any time. Users are responsible for reviewing the terms regularly.
          </Typography>
          <Typography variant="h5" sx={{textAlign : "center"}} gutterBottom>
           Payments and Refunds
          </Typography>

          <Typography variant="body1" gutterBottom>
            - Payments are processed securely, and users are responsible for providing accurate payment information.
          </Typography>
         
          <Typography variant="body1" gutterBottom>
            - Users can cancel bookings according to the specified cancellation policy. Refunds, if applicable, will be processed accordingly.
          </Typography>
         
          <Typography variant="h5" sx={{textAlign : "center"}} gutterBottom>
           Cancellation Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            - Users can cancel their taxi bookings  by contacting us directly through the provided contact information on the Savaari Taxi website.
          </Typography>
          <Typography variant="body1" gutterBottom>
            - Cancellations must be made at least 24 hours prior to the scheduled pickup time.
          </Typography>
         
          <Typography variant="body1" gutterBottom>
            - Upon successful cancellation, we will proceed to initiate a full refund of the amount paid by the user.  
          </Typography>
        </TextContainer>
        {/* <Link to="/Booking"> */}
          <BackButton onClick={goBack} variant="contained" color="primary">Back</BackButton>
        {/* </Link> */}
      </Container></>
  )
}

export default TermsAndCondition