import { SEARCH_FOR_CAB_REQUEST, SEARCH_FOR_CAB_SUCCESS, SEARCH_FOR_CAB_FAIL,
    GET_CABDETAILS_REQUEST, GET_CABDETAILS_SUCCESS, GET_CABDETAILS_FAIL } from "../Constants/CabConstants";

const initialState = { loading: false, error: null }

export const cabDetailsReducer = (state = { ...initialState, cabDetails : [] }, { type, payload }) => {
    switch (type) {
        case  GET_CABDETAILS_REQUEST:
            {
                return {
                    ...state,
                    loading: true
                }
            };
        case GET_CABDETAILS_SUCCESS:
            {
                return {
                    cabDetails : payload,
                    loading: false
                }
            };
        case GET_CABDETAILS_FAIL:
            {
                return {
                    ...state,
                    loading: false,
                    error: payload,
                }
            };

        default: {
            return state
        }

    }
}
export const cabSearchDetailsReducer = (state = { ...initialState, searchDetails : [] }, { type, payload }) => {
    switch (type) {
        case SEARCH_FOR_CAB_REQUEST:
            {
                return {
                    ...state,
                    loading: true
                }
            };
        case SEARCH_FOR_CAB_SUCCESS:
            {
                return {
                    searchDetails : payload,
                    loading: false
                }
            };
        case SEARCH_FOR_CAB_FAIL:
            {
                return {
                    ...state,
                    loading: false,
                    error: payload,
                }
            };

        default: {
            return state
        }

    }
}