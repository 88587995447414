import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import TaxiImg from "../../asset/Images/Taxi.png";
import TaxiImg from "../../asset/Images/Taxi.png";
import { Box, styled } from "@mui/material";

const AboutUs = () => {
  const MediaBox = styled(Box)`
    width: 40%;
    border: solid 5px blue;
    overflow: visible;
    &:hover {
      position: relative;
    }

    @media (max-width: 600px) {
      width: 100%;
      border: none;
      &:hover {
        position: static;
      }
    }
  `;

  const CardImg = styled(CardMedia)`
    minwidth: 200;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 500px;
    transition: all 0.5s;
    &:hover {
      position: relative;
      left: -20px;
      top: 20px;
    }

    @media (max-width: 600px) {
      height: 250px;
    }
  `;
  const AboutCard = styled(Card)`
    display: flex;
    height: auto;
    overflow: visible;
    box-shadow: none;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  `;
  return (
    <AboutCard sx={{}}>
      <MediaBox>
        <CardImg component="img" image={TaxiImg} alt="Taxi Img" />
      </MediaBox>

      <CardContent
        sx={{
          flex: 1,
          padding: "50px",
          boxShadow: "none",
          "@media (max-width: 600px)": { padding: "10px" },
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "700" }} gutterBottom>
          About Us
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: "700" }} gutterBottom>
          No. 1 TAXI BOOKING IN DEHRADUN
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "500", fontSize: "1.1rem" }}
          gutterBottom
        >
          {/* We are one of the best tour and travel company which gives you the best tour and takes you to your destination with full of comfort and satisfaction. Customer satisfaction is our uppermost priority. */}
          Welcome to Aayan taxi service ,
          your go-to destination for affordable and reliable car rentals.
          Whether you need a car for a day trip or a month-long holiday, we have
          you covered. Book now and enjoy the ride!<br/> Founded in 2021, Aayan taxi service has been providing
          quality car rental services to customers across the country for over a
          decade. We pride ourselves on our commitment to customer satisfaction
          and our extensive fleet of vehicles with locations in major cities and airports across the country,
          Aayan taxi service makes it easy to rent a car wherever you are. Our
          convenient pick-up and drop-off options make your rental experience
          hassle-free.<br/> Our customer service team is available 24/7 to answer any questions or concerns you
          may have. We are committed to providing excellent service and ensuring
          that our customers have a positive rental experience.
        </Typography>
      </CardContent>
    </AboutCard>
  );
};

export default AboutUs;
