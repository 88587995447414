import React from "react";
import styled from "styled-components";
import { Card, Button, Box } from "@mui/material";
import * as variable from "../../CSS Variable/Variable"
import Image1 from "../../asset/Images/Service1.jpeg"
import Image2 from "../../asset/Images/Service2.jpeg"
import Image3 from "../../asset/Images/Service3.jpeg"
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  @media (max-width: 480px) {
    flex-direction : column 
  }
`;

const Heading = styled.h2`
  width: 100%;
  text-align: center;
`;

const CardContainer = styled(Card)`
  position: relative;
  height: 450px;
  overflow: hidden;
  position: relative;
  left: -26px;
  top: 26px;


  @media (max-width: 768px) {
    width: 45%;
  }

  @media (max-width: 480px) {
    left: 0;
    top: 0;
    width : 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border : solid 1px gray
`;

const CardHeading = styled.h3`
  font-size: 1.5rem;
  z-index: 2;
  color: #fff;
  position: relative;
  background : ${variable.Primary};
  padding: 10px;
  width : 100%;
  margin : 0;
  text-align : center;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const BookNowButton = styled(Button)`
background : ${variable.Primary};
padding : 1% 3%;
`

const BorderedContainer = styled(Box)`
border: Solid 4px;
 width: 25%;
  height: fit-content;
   color : blue;
   @media (max-width: 480px) {
    width: 100%;
    margin-bottom : 10px;
  }

`

const Services = () => {
    const handleBook = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }
    return (
        <>
            <Heading>SERVICES</Heading>
            <Container>
                <BorderedContainer>
                    <CardContainer>
                        <CardHeading>ONE-WAY  SERVICE</CardHeading>
                        <Image src={Image1} alt="One Way" />
                        <ButtonContainer>
                           <BookNowButton onClick={handleBook} variant="contained" color="primary">
                                BOOK NOW
                            </BookNowButton>  
                        </ButtonContainer>
                    </CardContainer>
                </BorderedContainer>

                <BorderedContainer>
                    <CardContainer>
                        <CardHeading>ROUND  SERVICE</CardHeading>
                        <Image src={Image2} alt="Round" />
                        <ButtonContainer>
                        
                          <BookNowButton onClick={handleBook} variant="contained" color="primary">
                                BOOK NOW  
                            </BookNowButton>
                        </ButtonContainer>
                    </CardContainer>
                </BorderedContainer>
                <BorderedContainer>
                    <CardContainer>

                        <CardHeading>LOCAL  SERVICE</CardHeading>
                        <Image src={Image3} alt="Local" />
                         <ButtonContainer>
                         
                          <BookNowButton onClick={handleBook} variant="contained">BOOK NOW</BookNowButton>
                   
                        </ButtonContainer>
                    </CardContainer>
                </BorderedContainer>
            </Container>
        </>
    );
};

export default Services;
