import React, { useState, useEffect } from 'react';
import FiltersSidebar from "./FiltersSidebar";
import Header from '../Header/Header';
import { Box, Toolbar, styled, Tabs, Tab } from '@mui/material';
import RoundTrip from '../Search/RoundTrip';
import * as variable from "../../CSS Variable/Variable";
import ResultsCard from './ResultsCard';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from "../Footer/Footer";
import NoCabsAvailable from './NoCabAvialable';
import SearchHeader from '../SerachHeader/SearchHeader';
import Loader from '../Loader/Loader';

const FilterBox = styled(Box)`
  width : 20%;
  position : sticky;
  @media (max-width: 600px) {
      display : none;
  }
`;

const PageContainer = styled(Box)`
  margin: auto;
  margin-top : 5vh;
  width: 84vw;
  max-width: 1500px;
  min-width: 1150px;
  @media (max-width: 480px) {
      max-width: 480px;
      min-width: 100vw;
      margin-top : 2vh;
  }
`;

const SearchContainer = styled(Box)`
  display : flex;
  align-items : center;
  width: 100%;
  margin: auto;
  background : #F7F7F7;
  margin-bottom : 10px;
  position: sticky;
  top: 13vh;
  z-index: 1000000;
  @media (max-width: 600px) {
      display : none;
  }
`;

const ResultsContainer = styled(Box)`
  display : flex;
  gap : 1%;
`;

function RentalSearchResult() {
    const { loading, searchDetails } = useSelector((state) => state.searchCab);
    const { cabDetails } = useSelector((state) => state.cabDetails);
    const [activeTab, setActiveTab] = useState('');

    // Extract unique fare types from cab details
    const fareTypes = Array.from(new Set(cabDetails.flatMap(cab => cab.fareForRental.map(fare => fare.type))));

    // Set the default active tab to the first fare type
    useEffect(() => {
        if (fareTypes.length > 0 && !activeTab) {
            setActiveTab(fareTypes[0]);
        }
    }, [fareTypes, activeTab]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const getFilteredCabDetails = () => {
        return cabDetails
            .filter(cab => cab.fareForRental.some(fare => fare.type === activeTab))
            .map(cab => ({
                ...cab,
                fareForRental: cab.fareForRental.filter(fare => fare.type === activeTab)
            }));
    };

    return (
        <>
            <Header />
            {loading && <PageContainer><Loader /></PageContainer>}

            {!loading && searchDetails.searchedResult.success === true ? (
                <PageContainer>
                    <SearchContainer>
                        <SearchHeader />
                    </SearchContainer>

                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        {fareTypes.map((type) => (
                            <Tab key={type} label={type} value={type} />
                        ))}
                    </Tabs>

                    <ResultsContainer>
                        {/* <FilterBox>
                            <FiltersSidebar />
                            <Toolbar />
                        </FilterBox> */}

                        <Box sx={{ width: "100%" }}>
                            {getFilteredCabDetails().map((e) => (
                                <ResultsCard
                                    key={e._id}
                                    btnShow={true}
                                    carName={e.carName}
                                    img={e.image.url}
                                    CabType={e.carType}
                                    seats={e.seats}
                                    extraFare={e.extraFare}
                                    fuelType={e.fuelType}
                                    tripType={searchDetails.userQuery.tripType}
                                    fareForRental={e.fareForRental[0]?e.fareForRental[0].fare : 1000}
                                    typeForRental={e.fareForRental[0]?e.fareForRental[0].type : "8hrs 80km"}
                                />
                            ))}
                        </Box>
                    </ResultsContainer>
                </PageContainer>
            ) : (
                <PageContainer>
                    <SearchContainer>
                        <SearchHeader />
                    </SearchContainer>
                    <NoCabsAvailable />
                </PageContainer>
            )}
            <Footer />
        </>
    );
}

export default RentalSearchResult;
