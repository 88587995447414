import React from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const TransactionMessageWrapper = styled(Paper)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    width: 300px;
    margin: 0 auto;
    margin-top : 20vh;
  }

  &.success {
    background-color: #28a745; /* Green background for success */
  }

  &.failure {
    background-color: #dc3545; /* Red background for failure */
  }

  .icon {
    font-size: 60px;
    margin-bottom: 10px;
    font-color : white;
  }
`;

const TransactionSuccess = () => {
  const [seconds, setSeconds] = useState(4);
  const navigate = useNavigate();

  useEffect(()=>{

    if (seconds === 0) {
      navigate("/")
    }

    const interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

   return () => clearInterval(interval);
  }, [seconds]);

  return (
    <>
    <TransactionMessageWrapper className="success">
      <div className="icon">&#10003;</div>
      <h2>Transaction Successful</h2>
    </TransactionMessageWrapper>
    <p style={{"textAlign" : "center"}} >redirecting in {seconds} seconds.....</p>
    <p style={{"textAlign" : "center"}} >if not redirected <Link to="https://savaaritaxi.com"> click here </Link>  </p>
    </>
  );
};

export default TransactionSuccess;
