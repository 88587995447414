import React, { useState } from "react";
import CabContext from "./CabContext";

function Contxt(props) {
  const [bookingAmount, setBookingAmount] = useState(0);
  const [cabType, setCabType] = useState("Hatchback");
  const [img, setImg] = useState("hatchback");
  const [carName, setCarName] = useState("NA");
  const [paymentType, setPaymentType] = useState("Pay Later");
  const [rentalType, setRentalType] = useState("8 hrs 80km");

  return (
    <CabContext.Provider
      value={{
        bookingAmount,
        setBookingAmount,
        cabType,
        setCabType,
        img,
        setImg,
        paymentType,
        setPaymentType,
        carName,
        setCarName,
        rentalType,
        setRentalType,
      }}
    >
      {props.children}
    </CabContext.Provider>
  );
}

export default Contxt;
