import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Component/Homepage/Homepage";
import SearchResult from "./Component/SearchResult/SearchResult";
import BookingPage from "./Component/BookingPage/BookingPage";
import BookingForm from "./Component/Booking Form/BookingForm";
import BookingConfirm from "./Component/Booking Form/BookingConfirm";
import Context from "./Context";
import TermsAndCondition from "./Component/TermsAndConditions/TermsAndCondition";
import "./App.css"
import FloatingIcons from "./Component/FloatingIcons/FloatingIcons";
import RoundWayPrice from "./Component/PricePage/RoundWayPrice";
import OneWayPrice from "./Component/PricePage/OneWayPrice";
import LocalWayPrice from "./Component/PricePage/LocalWayPrice";
import Transaction from "./Other/Transaction";
import RentalSearchResult from "./Component/SearchResult/RentalSearchResult";




function App() {
  return (
    <HashRouter>
      <Context>
        <FloatingIcons/>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/SearchedResult" element={<SearchResult />} />
          <Route path="/RentalSearchedResult" element={<RentalSearchResult />} />
          <Route path="/CabDescription" element={<BookingPage />} />
          <Route path="/Booking" element={<BookingForm />} />
          <Route path="/BookingSuccess" element={<BookingConfirm />} />
          <Route path="/TermsAndConditions" element={<TermsAndCondition/>} />
          <Route path="/RoundWayService" element={<RoundWayPrice/>} />
          <Route path="/OneWayService" element={<OneWayPrice/>} />
          <Route path="/RentalService" element={<LocalWayPrice/>} />
          <Route path="booking/checkPaymentStatus" element={<Transaction/>} />
        </Routes>
      </Context>
    </HashRouter>
  );
}

export default App;
