import React, { useState, useEffect, useImperativeHandle } from 'react';
import { TextField, Box, styled, Autocomplete, IconButton, Button } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCabSearchDetails } from '../../action/CabAction'
import * as variable from "../../CSS Variable/Variable"
import { forwardRef } from 'react';

const FormInput = styled(TextField)`
  border-radius: none;
  margin: 1%;
  background: #ffffff;
  border-color: blue;
  @media (max-width: 600px) {
    width : 100%;
    margin-bottom : 10px;
    border: none;
  }
`;

const HorizontalBox = styled(Box)({
  display: 'inline-block',
  verticalAlign: 'top',
});

const SwapIconButton = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 0,
  fontSize: '2rem',
  backgroundColor: 'blue',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  "@media (max-width: 600px)": {
    display: "none"
  }
}));

const SearchButton = styled(Button)`
background : ${variable.Primary};
color : white;
border-radius : 10px;
height : 18%;
min-width : 10%;
font-size: 1.5rem;
font-weight : 600;
padding : 0 4% ;
position: absolute;
left: 43%;
top: 90%;
@media (max-width: 600px)
 {
    height: 10%;
    padding: 5% 21%;
    font-weight: 400;
    font-size: 1.5rem;
    top: 94%;
    position: relative;
    left: 0;
    display: block;
    margin: auto;
    margin-bottom: 10px;
}`

const FieldBox = styled(Box)`
display: flex;
gap: 3%;
@media(max-width: 600px) {
  flex-direction: column;
}
`


const OneWay = forwardRef(({ width, BtnHide }, ref) => {
  const [searchDetails, setSearchDetails] = useState({});
  const [options, setOptions] = useState([]);
  const [fromValue, setFromValue] = useState('Dehradun');
  const [toValue, setToValue] = useState('Delhi');
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10);
  const todayTime = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  console.log(todayTime)
  const hostName = process.env.REACT_APP_SERVER

  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchDetails({ ...searchDetails, [name]: value })
  };


  const handleSearch = () => {
    dispatch(getCabSearchDetails(searchDetails))
    navigate("/SearchedResult")
  }

  const handleInputChange = async (event, value) => {
    const response = await fetch(
      `${hostName}/autocomplete?input=${value}`
    );
    const data = await response.json();
    if (Array.isArray(data)) {
      setOptions(data);
    }
    else {
      setOptions([])
    }
  };
  
  useImperativeHandle(ref, () => ({
    handleSearch
  }));

  const isOptionEqualToValue = (option, value) =>
    option.description === value.description;



  useEffect(() => {
    setSearchDetails({
      from: fromValue,
      to: toValue,
      pickupDate: todayDate,
      pickupTime: todayTime,
      tripType: "one-way"
    });
  }, []);


  return (
    <>
      <form onSubmit={handleSearch}  ref={ref} >
        <FieldBox sx={{ width: `${width}` }}>
          <HorizontalBox sx={{ width: "29%" }}>
            <Autocomplete
              options={options}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.description}
              isOptionEqualToValue={isOptionEqualToValue}
              name="from"
              sx={{ "@media(max-width: 600px)": { width: "69vw" } }}
              onChange={(e, v) => {
                setFromValue(v.terms[0].value);
                setSearchDetails({ ...searchDetails, "from": v.terms[0].value })
              }}
              renderInput={(params) => (
                <FormInput
                  {...params}
                  label="From"
                  name="from"
                  variant="outlined"
                  defaultValue={fromValue}
                  value={fromValue}
                  required
                />
              )}
            />
          </HorizontalBox>

          {!BtnHide && <Box sx={{ position: 'absolute', width: '5%', left: "447px", top: "178px", zIndex: 5 }}>
            <SwapIconButton
              onClick={() => {
                setSearchDetails({ ...searchDetails, from: toValue, to: fromValue });
                setFromValue(toValue);
                setToValue(fromValue);
              }} >
              <SwapHorizIcon sx={{ color: "white" }} />
            </SwapIconButton>
          </Box>}

          <HorizontalBox sx={{ width: "29%" }}>
            <Autocomplete
              options={options}
              sx={{ "@media(max-width: 600px)": { width: "69vw" } }}
              onInputChange={handleInputChange}
              getOptionLabel={(option) => option.description}
              isOptionEqualToValue={isOptionEqualToValue}
              name="to"
              onChange={(e, v) => {
                setToValue(v.terms[0].value);
                setSearchDetails({ ...searchDetails, "to": v.terms[0].value })
              }}

              renderInput={(params) => (
                <FormInput
                  {...params}
                  label="To"
                  variant="outlined"
                  defaultValue={toValue}
                  value={toValue}
                  required
                />
              )}
            />
          </HorizontalBox>

          <HorizontalBox>
            <FormInput
              label="Departure"
              type="date"
              defaultValue={todayDate}
              name="pickupDate"
              variant="outlined"
              onChange={onChange}
              required
            />
          </HorizontalBox>

          <HorizontalBox>
            <FormInput
              label="Pickup Time"
              type="time"
              defaultValue={todayTime}
              name="pickupTime"
              variant="outlined"
              onChange={onChange}
              required
            />
          </HorizontalBox>
        </FieldBox>
        {!BtnHide && <SearchButton variant='contained' sx={{ background: `${variable.Primary}` }} type='submit' >Search</SearchButton>}
      </form>
    </>
  );
})

export default OneWay;
